<template>
    <section id="UpdatePassword">
        <video src="/videos/promo.mp4" muted autoplay loop playsinline />
        <div class="card card--form">
            <img class="lockup" src="/images/lockup.svg" alt="BARF Book">
            <form @submit="submit" v-if="!success">
                <input type="password" placeholder="New Password" v-model="password">
                <input type="password" placeholder="Confirm New Password" v-model="confirm">
                <Button arrow :disabled="!isReady">Update Password</Button>
            </form>
            <p class="error" v-if="error">{{ error }}</p>
            <div class="success" v-if="success">
                <h3>Success!</h3>
                <p>Your password has been successfully updated. You can now log in with your new password.</p>
                <router-link :to="{ name: 'Home' }">
                    <Button arrow>Login</Button>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>

    // Components.
    import Button from '@/components/Button.vue';

    // Firebase.
    import { updatePassword } from '@/firebase/auth.js';

    export default {
        name: 'UpdatePassword',
        components: {
            Button,
        },
        data() {
            return {
                password: '',
                confirm: '',
                error: null,
                success: false,
            }
        },
        computed: {
            isReady() {
                return (this.password !== '' && this.confirm !== '');
            }
        },
        methods: {
            submit(e) {

                e.preventDefault();

                this.error = null;
                this.$store.dispatch('updateLoading', 1);

                const password = this.password;
                const confirm = this.confirm;

                if (password !== confirm) {
                    this.error = `Passwords do not match.`;
                    this.$store.dispatch('updateLoading', -1);
                } else if (password.length < 6) {
                    this.error = `Password must be at least six characters.`;
                    this.$store.dispatch('updateLoading', -1);
                } else if (!this.$route.query.oobCode) {
                    this.error = `Password recovery token is invalid.`;
                    this.$store.dispatch('updateLoading', -1);
                } else {
                    updatePassword(this.$route.query.oobCode, password).then(() => {
                        this.success = true;
                        this.$store.dispatch('updateLoading', -1);
                    }).catch(error => {
                        this.error = error;
                        this.$store.dispatch('updateLoading', -1);
                    });
                }

            }
        },
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    video {
        display: block;
        width: 100%;
    }

    .card {
        position: relative;
        margin: (size(Large) * -1) auto size(Medium);
    }

    .lockup {
        width: 100%;
        margin-bottom: size(Large);
    }

    input:not(:first-of-type),
    p,
    .Button {
        margin-top: size(Small);
    }

    .success .Button {
        margin-top: size(Large);
    }

</style>
